import React from 'react';
import JoinUs from "./JoinUs";
import {Link, useLocation} from "react-router-dom";

// Importing icons and logo
 import logo from '../assets/images/logo.svg';
import mapIcon from '../assets/images/mao.svg';
import whiteMailIcon from '../assets/images/email-white.svg';

const Footer = () => {
    const location = useLocation();
    const isContactUsRoute = location.pathname === '/contact-us';


    const fbIcon = <svg className='h-6 w-6' viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_1807)">
            <path
                d="M17.0001 0.0707703C7.6113 0.0707703 0 7.68207 0 17.0709C0 25.4915 6.12887 32.4652 14.1648 33.8157V20.6176H10.0639V15.8681H14.1648V12.366C14.1648 8.30263 16.6466 6.08831 20.272 6.08831C22.0083 6.08831 23.5005 6.21769 23.9337 6.27468V10.522L21.4192 10.5232C19.448 10.5232 19.068 11.4597 19.068 12.8345V15.8657H23.7714L23.158 20.6151H19.068V33.9292C27.4791 32.9055 34 25.7542 34 17.066C34 7.68207 26.3887 0.0707703 17.0001 0.0707703Z"
                fill="#FF6500"/>
        </g>
        <defs>
            <clipPath id="clip0_1_1807">
                <rect width="34" height="34" fill="white"/>
            </clipPath>
        </defs>
    </svg>


    const instaIcon = <svg className='w-6 h-6' viewBox="0 0 34 34" fill="#FFFFFF"
                           xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_1825)">
            <path
                d="M20.2539 17C20.2539 18.7971 18.7971 20.2539 17 20.2539C15.2029 20.2539 13.7461 18.7971 13.7461 17C13.7461 15.2029 15.2029 13.7461 17 13.7461C18.7971 13.7461 20.2539 15.2029 20.2539 17Z"
            />
            <path
                d="M24.6098 11.2429C24.4534 10.819 24.2038 10.4354 23.8796 10.1205C23.5647 9.79623 23.1813 9.54669 22.7572 9.39027C22.4132 9.25668 21.8965 9.09767 20.9447 9.05435C19.9152 9.0074 19.6065 8.99728 17.0001 8.99728C14.3934 8.99728 14.0847 9.00714 13.0554 9.05409C12.1036 9.09767 11.5867 9.25668 11.243 9.39027C10.8188 9.54669 10.4352 9.79623 10.1205 10.1205C9.79628 10.4354 9.54674 10.8188 9.39006 11.2429C9.25647 11.5869 9.09746 12.1039 9.05414 13.0556C9.00719 14.0849 8.99707 14.3936 8.99707 17.0003C8.99707 19.6067 9.00719 19.9154 9.05414 20.945C9.09746 21.8967 9.25647 22.4134 9.39006 22.7574C9.54674 23.1815 9.79602 23.5649 10.1203 23.8798C10.4352 24.2041 10.8186 24.4536 11.2427 24.61C11.5867 24.7439 12.1036 24.9029 13.0554 24.9462C14.0847 24.9932 14.3931 25.003 16.9998 25.003C19.6068 25.003 19.9155 24.9932 20.9445 24.9462C21.8962 24.9029 22.4132 24.7439 22.7572 24.61C23.6085 24.2816 24.2814 23.6087 24.6098 22.7574C24.7434 22.4134 24.9024 21.8967 24.946 20.945C24.9929 19.9154 25.0028 19.6067 25.0028 17.0003C25.0028 14.3936 24.9929 14.0849 24.946 13.0556C24.9027 12.1039 24.7437 11.5869 24.6098 11.2429ZM17.0001 22.0127C14.2315 22.0127 11.9872 19.7686 11.9872 17C11.9872 14.2314 14.2315 11.9874 17.0001 11.9874C19.7684 11.9874 22.0127 14.2314 22.0127 17C22.0127 19.7686 19.7684 22.0127 17.0001 22.0127ZM22.2109 12.9606C21.5639 12.9606 21.0394 12.4361 21.0394 11.7892C21.0394 11.1423 21.5639 10.6178 22.2109 10.6178C22.8578 10.6178 23.3823 11.1423 23.3823 11.7892C23.3821 12.4361 22.8578 12.9606 22.2109 12.9606Z"
            />
            <path
                d="M17 0C7.61259 0 0 7.61259 0 17C0 26.3874 7.61259 34 17 34C26.3874 34 34 26.3874 34 17C34 7.61259 26.3874 0 17 0ZM26.7028 21.0246C26.6556 22.0637 26.4904 22.7732 26.2491 23.3942C25.742 24.7055 24.7055 25.742 23.3942 26.2491C22.7735 26.4904 22.0637 26.6554 21.0248 26.7028C19.9839 26.7503 19.6513 26.7617 17.0003 26.7617C14.3489 26.7617 14.0166 26.7503 12.9754 26.7028C11.9365 26.6554 11.2268 26.4904 10.6061 26.2491C9.95445 26.004 9.36458 25.6198 8.87691 25.1231C8.38042 24.6357 7.99625 24.0455 7.75111 23.3942C7.50987 22.7735 7.34464 22.0637 7.29742 21.0248C7.24944 19.9836 7.23828 19.6511 7.23828 17C7.23828 14.3489 7.24944 14.0164 7.29716 12.9754C7.34438 11.9363 7.50935 11.2268 7.7506 10.6058C7.99573 9.95445 8.38016 9.36432 8.87691 8.87691C9.36432 8.38016 9.95445 7.99599 10.6058 7.75085C11.2268 7.50961 11.9363 7.34464 12.9754 7.29716C14.0164 7.24969 14.3489 7.23828 17 7.23828C19.6511 7.23828 19.9836 7.24969 21.0246 7.29742C22.0637 7.34464 22.7732 7.50961 23.3942 7.7506C24.0455 7.99573 24.6357 8.38016 25.1234 8.87691C25.6198 9.36458 26.0043 9.95445 26.2491 10.6058C26.4906 11.2268 26.6556 11.9363 26.7031 12.9754C26.7506 14.0164 26.7617 14.3489 26.7617 17C26.7617 19.6511 26.7506 19.9836 26.7028 21.0246Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_1825">
                <rect width="34" height="34" fill="white"/>
            </clipPath>
        </defs>
    </svg>;
    const linkedinIcon = <svg className='w-6 h-6' viewBox="0 0 34 34" fill="#FFFFFF"
                              xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_1829)">
            <path
                d="M16.9995 0C7.61211 0 -0.000488281 7.61259 -0.000488281 17C-0.000488281 26.3874 7.61211 34 16.9995 34C26.3869 34 33.9995 26.3874 33.9995 17C33.9995 7.61259 26.3869 0 16.9995 0ZM12.0595 25.6992H7.91924V13.2431H12.0595V25.6992ZM9.9895 11.5422H9.96252C8.57318 11.5422 7.67462 10.5858 7.67462 9.39052C7.67462 8.16823 8.60068 7.23828 10.017 7.23828C11.4333 7.23828 12.3049 8.16823 12.3319 9.39052C12.3319 10.5858 11.4333 11.5422 9.9895 11.5422ZM26.9885 25.6992H22.8487V19.0355C22.8487 17.3608 22.2492 16.2187 20.7512 16.2187C19.6075 16.2187 18.9263 16.9891 18.627 17.7328C18.5175 17.9989 18.4908 18.3709 18.4908 18.7432V25.6992H14.3508C14.3508 25.6992 14.405 14.4117 14.3508 13.2431H18.4908V15.0068C19.041 14.158 20.0254 12.9508 22.222 12.9508C24.946 12.9508 26.9885 14.731 26.9885 18.5569V25.6992Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_1825">
                <rect width="34" height="34" fill="white"/>
            </clipPath>
        </defs>
    </svg>;
    const xIcon = <svg className='w-6 h-6' viewBox="0 0 34 34" fill="#FFFFFF"
                       xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_1831)">
            <path
                d="M17 0C7.61148 0 0 7.61148 0 17C0 26.3885 7.61148 34 17 34C26.3885 34 34 26.3885 34 17C34 7.61148 26.3885 0 17 0Z"
            />
            <path
                d="M18.8598 15.4616L26.1373 7.00208H24.4128L18.0937 14.3474L13.0467 7.00208H7.22559L14.8576 18.1094L7.22559 26.9805H8.95022L15.6233 19.2236L20.9533 26.9805H26.7744L18.8594 15.4616H18.8598ZM9.57161 8.30037H12.2205L24.4135 25.7413H21.7646L9.57161 8.30037Z"
                fill="#511E34"/>
        </g>
        <defs>
            <clipPath id="clip0_1_1825">
                <rect width="34" height="34" fill="white"/>
            </clipPath>
        </defs>
    </svg>;
    const ytIcon = <svg className='w-6 h-6' viewBox="0 0 34 34" fill="#FFFFFF"
                        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_1850)">
            <path d="M14.8823 20.1849L20.4122 17L14.8823 13.8151V20.1849Z"/>
            <path
                d="M17 0C7.61259 0 0 7.61259 0 17C0 26.3874 7.61259 34 17 34C26.3874 34 34 26.3874 34 17C34 7.61259 26.3874 0 17 0ZM27.6224 17.0174C27.6224 17.0174 27.6224 20.4651 27.1851 22.1275C26.9399 23.0375 26.2224 23.755 25.3125 23.9999C23.65 24.4375 17 24.4375 17 24.4375C17 24.4375 10.3674 24.4375 8.68755 23.9825C7.77757 23.7376 7.06007 23.0199 6.81494 22.1099C6.37733 20.4651 6.37733 17 6.37733 17C6.37733 17 6.37733 13.5526 6.81494 11.8901C7.05981 10.9801 7.79495 10.245 8.68755 10.0001C10.35 9.5625 17 9.5625 17 9.5625C17 9.5625 23.65 9.5625 25.3125 10.0175C26.2224 10.2624 26.9399 10.9801 27.1851 11.8901C27.64 13.5526 27.6224 17.0174 27.6224 17.0174Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_1825">
                <rect width="34" height="34" fill="white"/>
            </clipPath>
        </defs>
    </svg>;

    return (
        <>
            {!isContactUsRoute &&
                <div className='mb-10'>
                    <JoinUs />
                </div>}

            <footer className="bg-dark text-white pt-12 flex flex-col">
                <div className="container mx-auto grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
                    {/* Logo and Description */}
                    <div className="flex flex-col items-center md:items-start text-center md:text-left lg:col-span-2">
                        <img src={logo} alt="Logo" className="w-20 mb-6"/>
                        <p className="leading-relaxed max-w-sm">
                            Every homemade dish tells a unique story, making us the vibrant hub of a global community
                            united by the love of homemade food.
                        </p>
                    </div>

                    {/* Navigation */}
                    <div className="flex flex-col items-center md:items-start text-center md:text-left">
                        <h3 className="font-bold mb-4">NAVIGATE</h3>
                        <ul className="space-y-2">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about">About Us</Link>
                            </li>
                            <li>
                                <Link to="/">You Are</Link>
                            </li>
                            <li>
                                <a href='/blog' >Blog</a>
                            </li>
                            <li>
                                <Link to="/">Podcast</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                    </div>


                    {/* Terms and Conditions */}
                    <div className="flex flex-col items-center md:items-start text-center md:text-left">
                        <h3 className="font-bold mb-4">TERMS & CONDITIONS</h3>
                        <ul className="space-y-2">
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                            <li><a href="/terms-and-conditions">Cottage Food Laws</a></li>
                        </ul>
                    </div>

                    {/* Contact Us */}
                    <div className="flex flex-col items-center md:items-start text-center md:text-left">
                        <h3 className="font-bold mb-4">CONTACT US</h3>
                        <p className="flex items-center justify-center md:justify-start mb-2">
                            <img src={mapIcon} alt="Map" className="w-4 h-4 inline-block mr-2"/>
                            7901 4th Street N. STE 300 St. Petersburg, FL 3370
                        </p>
                        <p className="flex items-center justify-center md:justify-start">
                            <img src={whiteMailIcon} alt="Email" className="w-4 h-4 inline-block mr-2"/>
                            Email: <a href="mailto:info@kitible.com">info@kitible.com</a>
                        </p>
                        <div className="flex space-x-4 mt-4 justify-center md:justify-start">
                            <a href="https://m.facebook.com/Kitible" target="_blank" rel="noopener noreferrer">
                                {fbIcon}
                            </a>
                            <a href="https://www.instagram.com/Kitible/" target="_blank" rel="noopener noreferrer">
                                {instaIcon}
                            </a>
                            <a href="https://www.linkedin.com/company/kitible" target="_blank" rel="noopener noreferrer">
                                {linkedinIcon}
                            </a>
                            <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                                {xIcon}
                            </a>
                            <a href="https://www.youtube.com/@Kitible" target="_blank" rel="noopener noreferrer">
                                {ytIcon}
                            </a>
                        </div>
                    </div>
                </div>
                <div className='self-center font-bold pt-12 pb-6'>
                    KITIBLE GROUP LLC
                </div>
            </footer>
        </>
    );

};

export default Footer;
