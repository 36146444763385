import React from 'react';
import headerVector from '../assets/images/header-vector.svg'
import userIcon from "../assets/images/user.svg";
import emailIcon from '../assets/images/email.svg';

import xIcon from '../assets/images/x.svg';
import ytIcon from '../assets/images/yt.svg';
import linkedinIcon from '../assets/images/linkedin.svg';
import fbIcon from '../assets/images/fb.svg';
import instaIcon from '../assets/images/insta.svg';

const ContactUsPage = () => {

    const socialLinks = [
        {
            name: 'Facebook',
            icon: fbIcon,
            link: 'https://m.facebook.com/Kitible',
        }, {
            name: 'X',
            icon: xIcon,
            link: 'https://twitter.com',
        },
        {
            name: 'YouTube',
            icon: ytIcon,
            link: 'https://www.youtube.com/@Kitible',
        },
        {
            name: 'LinkedIn',
            icon: linkedinIcon,
            link: 'https://www.linkedin.com/company/kitible',
        },

        {
            name: 'Instagram',
            icon: instaIcon,
            link: 'https://www.instagram.com/Kitible/',
        },
    ];
    return (
        <div className='flex flex-col w-full'>

            <div className='flex flex-col-reverse lg:flex-row justify-between items-center gap-10'>

                <div className='self-center w-full lg:w-1/2 uppercase px-10 lg:px-40'>
                    <h1 className='text-4xl md:text-6xl lg:text-9xl font-extrabold'>
                        Contact <br className='hidden md:block'/><span className='text-primary '>us</span>
                    </h1>
                </div>

                <div className='w-full lg:w-1/2 flex justify-center lg:justify-end'>
                    <img src={headerVector} className='w-full lg:w-auto self-end'/>
                </div>

            </div>

            <div
                className='w-full relative pt-10 pb-10 md:pb-0 md:pt-0 md:-top-32 flex flex-col lg:flex-row items-center gap-10 lg:gap-32 px-10 lg:px-40'>

                <div className='w-full lg:w-1/2 flex flex-col mx-auto'>
                    {contactForm()}
                </div>

                <div
                    className='w-full lg:w-1/2 flex flex-col uppercase text-xl md:text-2xl lg:text-3xl font-extrabold gap-6'>
                    <h1>
                        Join <span className='text-primary'>Kitible<br/></span>
                        Share Your Passion for<br/>Food and Earn
                    </h1>

                    <div className='flex flex-row gap-3'>
                        {socialLinks.map((icon) => <a href={icon.link} target='_blank' rel="noreferrer"> <img
                            src={icon.icon} alt={icon.name} className="w-8 h-8 lg:w-10 lg:h-10"/></a>)}
                    </div>

                    <div className="overflow-hidden rounded-xl w-full lg:w-96 h-48 shadow">
                        <iframe
                            className="w-full h-full"
                            loading="lazy"
                            allowFullScreen
                            referrerPolicy="no-referrer-when-downgrade"
                            src="https://www.google.com/maps?q=7901+4th+Street+N.+STE+300+St.+Petersburg,+FL+3370&output=embed"
                        >
                        </iframe>
                    </div>

                </div>

            </div>
        </div>
    );
};

const contactForm = () => <>
    <div className='relative mb-4'>
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={userIcon} alt="User Icon" className="w-6 h-6 text-gray-400"/>
            </span>
        <input
            type="text"
            name="name"
            placeholder='Name'
            className="w-full pl-12 py-3 text-base rounded-xl shadow-custom bg-white focus:outline-none placeholder-gray-500 text-gray-900"
        />
    </div>

    <div className='relative mb-4'>
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={emailIcon} alt="Email Icon" className="w-6 h-6 text-gray-400"/>
            </span>
        <input
            type="email"
            name="email"
            placeholder='Email'
            className="w-full pl-12 py-3 text-base rounded-xl shadow-custom bg-white focus:outline-none placeholder-gray-500 text-gray-900"
        />
    </div>

    <div className='relative mb-6'>
        <textarea
            rows="6"
            name="subject"
            placeholder='Subject'
            className="w-full py-3 text-base rounded-xl shadow-custom bg-white focus:outline-none placeholder-gray-500 text-gray-900 pl-4"
        />
    </div>

    <button
        name="mauticform[submit]"
        value="1"
        id="mauticform_prelaunchinvite1_submit" type="submit"
        className='mt-4 cursor-pointer hover:shadow-md inline-block text-base sm:text-lg rounded-full bg-gradient-to-r from-primary to-primary-400 py-2.5 px-10 font-bold text-white w-full text-center'>
        Send message
    </button>
</>

export default ContactUsPage;
