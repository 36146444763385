import React from 'react';
import FeatureSection from "../Components/FeatureSection";
import WhereWeAre from "../Components/WhereWeAre";
import HomeHeader from "../Components/HomeHeader";

const HomePage = () => {
    return (
        <div>
            <HomeHeader/>
            <FeatureSection/>
            <WhereWeAre/>
        </div>
    );
};

export default HomePage;