import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import logo from '../assets/images/logo.svg';

const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header
            className={`fixed w-full z-20 flex   h-16 sm:h-20  ${scrolled ? 'bg-white shadow-lg' : 'bg-transparent'}`}>
            <div className="flex justify-between md:justify-start gap-5  px-4 sm:px-16 items-center w-full ">
                {/* Logo */}
                <a href='/'>
                    <img src={logo} className='h-8 w-8 sm:h-12 sm:w-12' alt="Logo"/>
                </a>

                {/* Desktop Navigation */}
                <nav className="hidden md:flex space-x-4 sm:space-x-9 text-sm sm:text-lg">
                    <ul className="flex space-x-2 sm:space-x-9">
                        <li>
                            <Link to="/"
                                  className={`hover:text-orange-500 text-dark ${location.pathname === '/' ? 'font-bold ' : 'font-normal '}`}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/about"
                                  className={`hover:text-orange-500 text-dark ${location.pathname === '/about' ? 'font-bold ' : 'font-normal '}`}>
                                About us
                            </Link>
                        </li>
                        <li>
                            <Link to="/"
                                  className={`hover:text-orange-500 text-dark ${location.pathname === '/you-are' ? 'font-bold ' : 'font-normal '}`}>
                                You Are
                            </Link>
                        </li>
                        <li>
                            <a
                                href='/blog'

                                className={`hover:text-orange-500 text-dark ${location.pathname === '/blog' ? 'font-bold ' : 'font-normal '}`}>
                                Blog
                            </a>
                        </li>
                        <li>
                            <Link to="/"
                                  className={`hover:text-orange-500 text-dark ${location.pathname === '/podcast' ? 'font-bold ' : 'font-normal '}`}>
                                Podcast
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact-us"
                                  className={`hover:text-orange-500 text-dark ${location.pathname === '/contact-us' ? 'font-bold ' : 'font-normal '}`}>
                                Contact us
                            </Link>
                        </li>
                    </ul>
                </nav>

                {/* Hamburger Icon for Mobile */}
                <div className="md:hidden">
                    <button onClick={() => setMenuOpen(!menuOpen)}>
                        {!menuOpen ? (
                            // Hamburger Icon (SVG)
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7"/>
                            </svg>
                        ) : (
                            // Close Icon (SVG)
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        )}
                    </button>
                </div>
            </div>

            {/* Mobile Navigation */}
            {menuOpen && (
                <nav className="md:hidden bg-white w-full shadow-lg">
                    <ul className="flex flex-col items-center bg-white space-y-4 py-4">
                        <li>
                            <Link
                                to="/"
                                onClick={() => setMenuOpen(false)}
                                className={`hover:text-orange-500 text-dark ${location.pathname === '/' ? 'font-bold ' : 'font-normal '}`}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                onClick={() => setMenuOpen(false)}
                                className={`hover:text-orange-500 text-dark ${location.pathname === '/about' ? 'font-bold ' : 'font-normal '}`}
                            >
                                About us
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/"
                                onClick={() => setMenuOpen(false)}
                                className={`hover:text-orange-500 text-dark ${location.pathname === '/you-are' ? 'font-bold ' : 'font-normal '}`}
                            >
                                You Are
                            </Link>
                        </li>
                        <li>
                            <a
                                href='/blog' target="_blank"
                                onClick={() => setMenuOpen(false)}
                                className={`hover:text-orange-500 text-dark ${location.pathname === '/blog' ? 'font-bold ' : 'font-normal '}`}
                                >
                                Blog
                            </a>
                        </li>
                        <li>
                            <Link
                                to="/"
                                onClick={() => setMenuOpen(false)}
                                className={`hover:text-orange-500 text-dark ${location.pathname === '/podcast' ? 'font-bold ' : 'font-normal '}`}
                            >
                                Podcast
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contact-us"
                                onClick={() => setMenuOpen(false)}
                                className={`hover:text-orange-500 text-dark ${location.pathname === '/contact-us' ? 'font-bold ' : 'font-normal '}`}
                            >
                                Contact us
                            </Link>
                        </li>
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default Header;
