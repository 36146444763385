import React, {useEffect, useState} from 'react';
import email from '../assets/images/email.svg';
import user from '../assets/images/user.svg';
import Modal from './PreLaunchModal';

const JoinUs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (typeof MauticSDKLoaded === 'undefined') {
            window.MauticSDKLoaded = true;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'http://mautic.kitible.net/media/js/mautic-form.js?v166950f3';
            script.onload = function () {
                window.MauticSDK.onLoad();
            };
            document.head.appendChild(script);
            window.MauticDomain = 'http://mautic.kitible.net';
            window.MauticLang = {
                submittingMessage: "Please wait..."
            };
        } else if (typeof window.MauticSDK !== 'undefined') {
            window.MauticSDK.onLoad();
        }


        const form = document.getElementById('mauticform_prelaunchinvite1');

        const isVisible = (elem) => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

        form.addEventListener('submit', (e) => {

            setTimeout(() => {

                const errorMessages = form.querySelectorAll('.mauticform-errormsg');
                console.log(`errorMessages ${errorMessages}`)
                let hasErrors = false;
                errorMessages.forEach((errorMsg) => {
                    if (isVisible(errorMsg)) {
                        hasErrors = true;
                    }
                });


                if (!hasErrors) {
                    setIsModalOpen(true);
                }
            }, 200);
        });

    }, []);

    return (
        <>

            <section
                id='early'
                className=" mt-8 sm:mt-20 mx-4 sm:mx-16 flex flex-col sm:flex-row justify-between
                space-y-4  px-4 sm:px-10"
            >
                <div className='w-full sm:w-4/10 self-center '>
                    <h1 className='font-extrabold text-2xl sm:text-4xl leading-tight uppercase'>
                        Join <span className='text-primary'>Kitible<br/></span> Share Your Passion for<br/>Food and Earn
                    </h1>
                </div>

                <div className='w-full self-start  sm:w-4/10'>
                    <div id="mauticform_wrapper_prelaunchinvite1" className="mauticform_wrapper">
                        <form
                            autoComplete="false"
                            role="form"
                            method="post"
                            action="https://mautic.kitible.net/form/submit?formId=1"
                            id="mauticform_prelaunchinvite1"
                            data-mautic-form="prelaunchinvite1"
                            encType="multipart/form-data"
                        >
                            <div className="mauticform-error hidden" id="mauticform_prelaunchinvite1_error"></div>
                            <div className="mauticform-message hidden" id="mauticform_prelaunchinvite1_message"></div>
                            <div className="mauticform-innerform">
                                <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">
                                    <div id="mauticform_prelaunchinvite1_f_name1"
                                         className="mauticform-row mauticform-text mauticform-field-1 mauticform-required"
                                         data-validate="f_name1" data-validation-type="text">
                                        <label
                                            id="mauticform_label_prelaunchinvite1_f_name1"
                                            htmlFor="mauticform_input_prelaunchinvite1_f_name1"
                                            className="mauticform-label hidden"
                                        >
                                            Name
                                        </label>
                                        <div className='relative'>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                            <button type="button"
                                                    className="p-1 focus:outline-none focus:shadow-outline">
                                                <img src={user} className="w-5 h-5 sm:w-6 sm:h-6"/>
                                            </button>
                                        </span>
                                            <input
                                                type="text"
                                                name="mauticform[f_name1]"
                                                placeholder='Name'
                                                id="mauticform_input_prelaunchinvite1_f_name1"
                                                className="w-full shadow-custom text bg-white py-3 rounded-xl pl-12 focus:outline-none placeholder-[#511E34] text-base sm:text-lg font-medium"
                                            />
                                        </div>
                                        <span className="mauticform-errormsg" style={{display: 'none'}}>This is required.</span>
                                    </div>
                                    <div id="mauticform_prelaunchinvite1_email"
                                         className="mauticform-row mauticform-email mauticform-field-2 mauticform-required mt-4"
                                         data-validate="email" data-validation-type="email">
                                        <label
                                            id="mauticform_label_prelaunchinvite1_email"
                                            htmlFor="mauticform_input_prelaunchinvite1_email"
                                            className="mauticform-label hidden"
                                        >
                                            Email
                                        </label>
                                        <div className='relative'>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                            <button type="button"
                                                    className="p-1 focus:outline-none focus:shadow-outline">
                                                <img src={email} className="w-5 h-5 sm:w-6 sm:h-6"/>
                                            </button>
                                        </span>
                                            <input
                                                type="email"
                                                placeholder='Email'
                                                name="mauticform[email]"
                                                id="mauticform_input_prelaunchinvite1_email"
                                                className="w-full shadow-custom text bg-white py-3 rounded-xl pl-12 focus:outline-none placeholder-[#511E34] text-base sm:text-lg font-medium"
                                            />
                                        </div>
                                        <span className="mauticform-errormsg" style={{display: 'none'}}>This is required.</span>
                                    </div>
                                    <div id="mauticform_prelaunchinvite1_countrystate"
                                         className="mauticform-row mauticform-select mauticform-field-3 mt-4 hidden">
                                        <label
                                            id="mauticform_label_prelaunchinvite1_countrystate"
                                            htmlFor="mauticform_input_prelaunchinvite1_countrystate"
                                            className="mauticform-label hidden"
                                        >
                                            Country/State
                                        </label>
                                        <select
                                            name="mauticform[countrystate]"
                                            id="mauticform_input_prelaunchinvite1_countrystate"
                                            className="w-full shadow-custom  text bg-white py-3 rounded-xl pl-12 focus:outline-none placeholder-[#511E34] text-base sm:text-lg font-medium"
                                            defaultValue='United States'
                                        >
                                            <option value=""></option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Åland Islands">Åland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bonaire, Saint Eustatius and Saba">Bonaire, Saint Eustatius
                                                and
                                                Saba
                                            </option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="Brunei">Brunei</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaçao">Curaçao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Democratic Republic of the Congo">Democratic Republic of the
                                                Congo
                                            </option>
                                            <option value="East Timor">East Timor</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Eswatini">Eswatini</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands">Falkland Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea Bissau">Guinea Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and McDonald Islands">Heard Island and McDonald
                                                Islands
                                            </option>
                                            <option value="Holy See">Holy See</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran">Iran</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Ivory Coast">Ivory Coast</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Kosovo">Kosovo</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia">Macedonia</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia">Micronesia</option>
                                            <option value="Moldova">Moldova</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="North Korea">North Korea</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestine">Palestine</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Republic
                    of the Congo">Republic of the Congo
                                            </option>
                                            <option value="Réunion">Réunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russia">Russia</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Barthelemy">Saint Barthelemy</option>
                                            <option value="Saint Helena, Ascension and Tristan da Cunha">Saint Helena,
                                                Ascension and Tristan da Cunha
                                            </option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Martin">Saint Martin</option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the
                                                Grenadines
                                            </option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and the South Sandwich Islands">South Georgia
                                                and
                                                the South Sandwich Islands
                                            </option>
                                            <option value="South Korea">South Korea</option>
                                            <option value="South Sudan">South Sudan</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syria">Syria</option>
                                            <option value="Tahiti">Tahiti</option>
                                            <option value="Taiwan">Taiwan</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania">Tanzania</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="Unknown">Unknown</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Vietnam">Vietnam</option>
                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                            <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Yugoslavia">Yugoslavia</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                        <span
                                            style={{display: 'none'}}></span>
                                    </div>
                                    <button
                                        name="mauticform[submit]"
                                        value="1"
                                        id="mauticform_prelaunchinvite1_submit" type="submit"
                                        className='mt-4 cursor-pointer hover:shadow-md inline-block text-base sm:text-lg rounded-full bg-gradient-to-r from-primary to-primary-400 py-2.5 px-10 font-bold text-white w-full text-center'>
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                            <input type="hidden" name="mauticform[formId]" id="mauticform_prelaunchinvite1_id"
                                   value="1"/>
                            <input type="hidden" name="mauticform[return]" id="mauticform_prelaunchinvite1_return"
                                   value=""/>
                            <input type="hidden" name="mauticform[formName]" id="mauticform_prelaunchinvite1_name"
                                   value="prelaunchinvite1"/>
                        </form>
                    </div>
                </div>

            </section>
            <Modal show={isModalOpen} handleClose={() => setIsModalOpen(false)}/>
        </>
    );
};

export default JoinUs;
