import React from 'react';
import frameapp1 from '../assets/images/app-frame1.png'
import frameapp2 from '../assets/images/app-frame2.png'
import frameapp3 from '../assets/images/app-frame3.png'
import dropVectorReversed from "../assets/images/drop-vector-reversed.svg";

const HowItWorks = () => (
    <div className='relative'>
        <div className='absolute  -top-4 left-0 md:top-0 md:left-0'>
            <img src={dropVectorReversed} className='h-20 md:h-48'/>
        </div>
        <section className="relative mx-2 sm:mx-16 mt-10  sm:py-16  flex  flex-col justify-between  ">
            <h1 className='font-extrabold text-2xl sm:text-4xl leading-tight'>How It Works</h1>

            <div className='flex flex-col px-6 sm:px-16 lg:flex-row self-center w-full '>
                <img src={frameapp1} className='lg:w-1/3  pr-20 lg:pr-10 self-start lg:mr-0 object-contain  '/>
                <img src={frameapp2}
                     className='lg:w-1/3  pl-20 lg:pl-0 self-start lg:pr-10 lg:mr-0 lg:mt-28 object-contain '/>
                <img src={frameapp3}
                     className='lg:w-1/3  pr-20 lg:pr-0 self-start lg:pl-10 sm:mr-0 lg:mt-60 object-contain '/>
            </div>

        </section>

    </div>
);

export default HowItWorks;
