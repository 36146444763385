import React from 'react';
import googlePlay from "../assets/images/google-play.png";
import appleStore from "../assets/images/apple-store.png";
import heroImage from "../assets/images/hero-image.png";

const HomeHeader = () => {
    return (
        <div className=' flex flex-col-reverse sm:flex-row  justify-between '>

            <div className=' sm:w-6/10 py-5  sm:ml-16 mt-4 mr-4 sm:mt-10 sm:pr-40 px-4 sm:px-0
                          w-full flex sm:items-start items-center flex-col pt-10 sm:pt-24 text-center lg:text-start'>
                <h1 className='text-2xl sm:text-6xl font-extrabold leading-tight uppercase'>
                    <span className='text-primary'>Connecting</span> Kitchen to Table
                    <br/>
                    One <span className='text-primary'>Homemade</span> Dish
                    at a time
                </h1>


                <div className=' mt-4 lg:mt-24 text-base sm:text-xl text-[#A02F21] space-y-1 md:space-y-3'>
                    <p>We empower homechefs to share authentic homemade dishes,</p>
                    <p> connect food lovers, and build stronger communities.</p>
                </div>


                <div className='flex flex-row mt-20 gap-3 '>
                    <a href='https://play.google.com/store/apps/details?id=com.kitible.app' target='_blank'>
                        <img src={googlePlay} className='h-16'/>
                    </a>
                    <a href='https://apps.apple.com/us/app/kitible-kitchen-to-table/id6575607168' target='_blank'>
                        <img src={appleStore} className='h-16'/>
                    </a>
                </div>

            </div>

            <div className='w-full sm:w-3/10 flex items-end justify-end  sm:relative  self-start'>
                <img src={heroImage}/>
            </div>

        </div>

    );
};

export default HomeHeader;