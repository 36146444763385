import React from 'react';
import './index.css';
import Layout from "./Components/Layout";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUsPage";
import ContactUsPage from "./Pages/ContactUsPage";
import ScrollToTop from "./Components/ScrollToTop";

const App = () => (
    <div className="App">
        <Router>
            <ScrollToTop/>
            <Layout>

                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutUsPage/>}/>
                    <Route path="/contact-us" element={<ContactUsPage/>}/>
                </Routes>
            </Layout>
        </Router>
    </div>
);

export default App;
