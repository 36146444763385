import React, {useEffect, useState} from "react";
import {ComposableMap, Geographies, Geography} from "react-simple-maps";
import dropVectorReversed from "../assets/images/drop-vector-reversed.svg";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const stateStyles = {
    "Florida": {fill: "#FF6500"}, // Example: Nebraska
    // Add other states as needed
};

const MapChart = () => {

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const [tooltipContent, setTooltipContent] = useState("");
    const [position, setPosition] = useState({x: 0, y: 0});
    const [showTooltip, setShowTooltip] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768); // Adjust for small screen size
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Check screen size on initial render

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const handleMouseEnter = (event, stateName, tooltipText) => {
        setPosition({
            x: event.clientX,
            y: event.clientY
        });
        setTooltipContent(tooltipText || stateName);
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
        setTooltipContent("");
    };

    return (
        <div className='relative'>

            <div className='absolute -top-4 md:-top-16 left-0'>
                <img src={dropVectorReversed} className='h-20 md:h-44'/>
            </div>

            <div className=" flex flex-col px-6 sm:px-16 ">
                <h1 className='font-extrabold text-2xl sm:text-4xl z-10 uppercase'>
                    Where we are<br/>legal
                </h1>

                <div className="self-center w-[70vw] md:w-[50vw] relative mx-2 mt-4 sm: sm:mx-16 sm:mt-10  flex flex-col ">
                    <ComposableMap projection="geoAlbersUsa">
                        <Geographies geography={geoUrl}>
                            {({geographies}) =>
                                geographies.map((geo) => {
                                    const stateName = geo.properties.name;
                                    const style = stateStyles[stateName] || {fill: "#F5A623"}; // Default color

                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            style={{
                                                default: {
                                                    fill: style.fill,
                                                    outline: "none", stroke: "#ffffff",
                                                    strokeWidth: 0.3

                                                },
                                                hover: {
                                                    fill: "#A03621",
                                                    outline: "none",
                                                    stroke: "#ffffff",
                                                    strokeWidth: 0.3

                                                },
                                            }}
                                            onMouseEnter={(event) => handleMouseEnter(event, stateName,
                                                "Baked and confectionery goods only")}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                    );
                                })
                            }
                        </Geographies>
                    </ComposableMap>

                    {/* Tooltip with conditional rendering */}
                    {showTooltip && (
                        <div
                            className="absolute bg-[#511E34] text-white px-4 py-2 rounded-md shadow-lg"
                            style={{
                                top: position.y - (isSmallScreen ? 300 : 250), // Position tooltip based on screen size
                                left: isSmallScreen ? '50%' : position.x - 400, // Center tooltip on small screens
                                transform: isSmallScreen ? 'translateX(-50%)' : 'none', // Center horizontally on small screens
                                width: "200px",
                                zIndex: 10,
                            }}
                        >
                            <div className="flex items-center">
                                <div className="text-center">
                                    {tooltipContent}
                                </div>
                            </div>
                            <div
                                className="absolute top-full left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-8
                        border-r-8 border-t-8 border-t-[#511E34] border-l-transparent border-r-transparent"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
        ;
};

export default MapChart;
