import React, {useState} from 'react';
import mockup1 from '../assets/images/mockup-1.png';
import cash from '../assets/images/cash.svg';
import food from '../assets/images/food.svg';
import dropVector from '../assets/images/drop-vector.svg';

const FeatureSection = () => {
    const [, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);
    // <div className='absolute  -top-4 left-0 md:top-0 md:left-0'>

    return (
        <div className='relative  pb-16'>
            <div className='absolute bottom-5 md:bottom-0 right-0'>
                <img src={dropVector} className='h-20 md:h-52'/>
            </div>
            <section className="relative mt-2 py-5 px-6 mx-4 sm:mx-16 sm:mt-24 sm:py-16 sm:px-16
                flex flex-col-reverse sm:flex-row sm:justify-between items-center sm:items-center bg-white rounded-xl">
                <div
                    className='mt-3 sm:mt-0 font-extrabold text-2xl sm:text-4xl w-full sm:w-4/10 leading-tight uppercase'>
                    <span className='text-primary'>KITIBLE</span> is the vibrant hub of a global community united by the
                    love of homemade <span className='text-primary font-bold'>food</span>.
                </div>

                <div className='w-full sm:w-4/10 self-end' onClick={handleOpen}>
                    <div
                        className='  items-center flex flex-col justify-center cursor-pointer'>
                        <iframe
                            className='w-full h-72'
                            src="https://www.youtube.com/embed/p3DTcC_ThLA?si=2PkZGxMW8tMy2pY1"
                            title="Kitible is a vibrant hub of Homemade culinary creativity and community"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                </div>

            </section>


        </div>
    );
};




export default FeatureSection;
